import React, { useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styles from "./StatisticsPage.module.scss";
import filterIcon from "../../img/filterIcon.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ResetFiltersButton } from "../../components/ResetFiltersButton/ResetFiltersButton";
import { MultiSelect } from "../../components/Select/MultiSelect";
import { params, statisticsStatus } from "../../api/helpers/constants";
import { ClicksStatisticTable } from "../../components/Tables/ClicksStatisticTable";
import {
  getCountriesOptions,
  getFiltersThunk,
  getGlobalsFilters,
  getPwaGlobalData
} from "../../store/reducers/globalDataReducer";
import { Search } from "../../components/Search/Search";
import { useDebounce } from "../../hooks/useDebounce";
import { SelectList } from "../../types/global";
import { ParamsRowSelect } from "../../components/Select/ParamsRowSelect";
import { DateSelect } from "../../components/Select/DateSelect";

const statusOptions: SelectList = [
  { label: "Установки", value: statisticsStatus.install },
  { label: "Лиды", value: statisticsStatus.lead },
  { label: "Продажи", value: statisticsStatus.sale },
  { label: "Открытия", value: statisticsStatus.open },
];

export const ClicksStatisticsPage = () => {
  const dispatch = useAppDispatch();
  const filtersOptions = useAppSelector(getGlobalsFilters);
  const countriesList = useAppSelector(getCountriesOptions);

  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useDebounce((value) =>
      setSearchParams(_params => {
        if (value) {
          _params.set(params.search, value);
        } else {
          _params.delete(params.search);
        }
        return _params;
      }),
    searchParams.get(params.search) || "",
    1000,
  );


  const countriesListOptions = useMemo(() => {
    return countriesList
      .map(country => ({
        label: country.name,
        value: country.name
      }));
  }, [countriesList]);


  useEffect(() => {
    dispatch(getFiltersThunk());
    dispatch(getPwaGlobalData());
  }, [dispatch]);

  const location = useLocation();

  const [
    selectedPwas,
    selectedDomains,
    selectedCountries,
    selectedStatus,
  ] = useMemo(() => {
    return [
      params.pwa,
      params.domain,
      params.country,
      params.status,
    ].map(
      paramName => searchParams.get(paramName)
        ?.split(',')
        .filter(value => value !== '')
        .map(value => ({ label: value, value })) || []
    );
  }, [searchParams]);

  return (
    <>
      <div className="title-container">
        <h2>Статистика{location.pathname === "/statistics" ? " общая" : " по кликам"}</h2>
        <div className={styles.emptyDiv}></div>
      </div>
      <div className={`filters-panel ${styles.filtersBlock}`}>
        <img src={filterIcon} width={28} height={28} alt="filter icon"/>
        <div className={styles.filterContainer}>
          <MultiSelect
            options={filtersOptions.pwa}
            values={selectedPwas}
            onChange={(values) => setSearchParams(_params => {
              _params.set(params.pwa, values.map(el => el.value).join(','));
              return _params;
            })}
            placeholder="Выберите PWA"
          />
        </div>
        <div className={styles.filterContainer}>
          <MultiSelect
            options={filtersOptions.domain}
            values={selectedDomains}
            onChange={(values) => setSearchParams(_params => {
              _params.set(params.domain, values.map(el => el.value).join(','));
              return _params;
            })}
            placeholder="Выберите домен"
          />
        </div>
        <div className={styles.filterContainer}>
          <MultiSelect
            options={countriesListOptions}
            values={selectedCountries}
            onChange={(values) => setSearchParams(_params => {
              _params.set(params.country, values.map(el => el.value).join(','));
              return _params;
            })}
            placeholder="Выберите страну"
          />
        </div>
        <div className={styles.filterContainer}>
          <MultiSelect
            options={statusOptions}
            values={selectedStatus}
            onChange={(values) => setSearchParams(_params => {
              _params.set(params.status, values.map(el => el.value).join(','));
              return _params;
            })}
            placeholder="Выберите статус"
          />
        </div>
        <DateSelect/>
        {(searchParams.get(params.pwa)
          || searchParams.get(params.domain)
          || searchParams.get(params.country)
          || searchParams.get(params.date_after)
          || searchParams.get(params.date_before)
          || searchParams.get(params.status)
        ) && <ResetFiltersButton onClick={() => setSearchParams('')}/>}
      </div>
      <div className={styles.rightSide}>
        <div className={styles.rowSelect}>
          <ParamsRowSelect/>
        </div>
        <div className={styles.searchContainer}>
          <Search value={name} placeholder="Поиск по клик ID" onChange={(newValue) => setName(newValue)}/>
        </div>
      </div>
      <ClicksStatisticTable/>
    </>
  );
};
