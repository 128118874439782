import { authorizedRequests } from "./auth";
import { BASE_URL } from "./constants";
import { StatisticsClicks, StatisticsGeneral, StatisticsOptions } from "../types/statistics";
import { PaginatedResults, WrappedResults } from "./helpers/types";
import { getParamsStringNoPagination } from "./helpers/scripts";

export function getDownloadLink(params: URLSearchParams) {
  return BASE_URL + '/statistics/download/?' + getParamsStringNoPagination(params);
}

export async function getGeneralStatistics(params: URLSearchParams, signal: AbortSignal) {
  const response = await authorizedRequests.get(
    BASE_URL + '/statistics/?' + params,
    { signal }
  );
  return response.data as PaginatedResults<StatisticsGeneral[]> & {
    total_clicks: number,
    total_installs: number,
    total_opens: number,
    total_leads: number,
    total_sales: number,
  };
}

export async function getClicksStatistics(params: URLSearchParams, signal:AbortSignal) {
  const response = await authorizedRequests.get(
    BASE_URL + '/statistics/by_click/?' + params,
    { signal }
  );
  // const response = await authorizedRequests.get(
  //   BASE_URL + '/statistics/by_click/?sort=last_30_days'
  // );
  return response.data as PaginatedResults<StatisticsClicks[]>;
}

export async function getDomainFilterList() {
  const response = await authorizedRequests.get(
    BASE_URL + '/pwa/domains/filter_list/'
  );
  return response.data as WrappedResults<{ id: number, address: string }[]>;
}


export async function getPwaFilterList() {
  const response = await authorizedRequests.get(
    BASE_URL + '/pwa/pwa/filter_list/'
  );
  return response.data as WrappedResults<{ id: number, name: string }[]>;
}
